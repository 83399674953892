* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}

:root {
    --black: #2e2d2c;
    --blue: #135fa8;
}

img {
    max-width: 100px;
}

body {
    background-color: black;
    color: white;
}
button {
    padding: 10px;
    background-color: var(--black);
    border: none;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    border-radius: 10px;
    transition: 300ms;
    cursor: pointer;
    width: 100%;
    opacity: 1;
    height: 100%;

    &:disabled {
        width: 0%;
        opacity: 0;
        height: 0px;
        cursor: auto;
        padding: 0;
    }
}

.container {
    margin: 0 auto;
    padding: 0 20px;
}

.converter {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
    background-color: var(--black);
}

.converter__container {
    background-color: var(--blue);
    padding: 50px;
    border-radius: 10px;
    max-width: 1000px;
}

.converter__container form {
    display: flex;
    flex-direction: column;
    padding-block: 20px;
}

.converter__container-text {
    padding-block: 20px;
    border-bottom: 2px solid white;
}

.converter__files-single {
    display: grid;
    grid-template-columns: 80% 20%;
    // border-bottom: 1px solid white;
    background-color: #f58f94;
    color: black;
    padding: 10px;
    border-radius: 5px;
    animation: fadein 300ms ease-in forwards;
    transform: translateX(-20px);
    opacity: 0;
    font-weight: 700;
}

.converter__files {
    padding-block: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    &:empty {
        padding-block: 0px;
    }
}

.converter {
    &__sliders {
        &-single {
            display: flex;
            margin-bottom: 10px;

            * {
                margin-right: 20px;
            }

            input {
                // &::-webkit-slider-runnable-track {
                //     background-color: red;
                // }

                // &::-webkit-slider-thumb {
                //     background-color: yellow;
                // }
                background-color: rgba(255, 0, 0, 0);

                &::-moz-range-track {
                    background-color: #f58f94;
                    height: 3px;
                }

                &::-moz-range-thumb {
                    background-color: #f58f94;
                    border: none;
                }
            }
        }
    }
}

@keyframes fadein {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.converter__saved {
    font-size: 24px;
}
